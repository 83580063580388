import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import FormPage from "./pages/FormPage/FormPage";
import "./themes/theme.css";
import { StyledLogoWrapper, StyledNav } from "./styled-components/StyledNav";
import WizardForm from "./components/WizardForm";
import Icon from "./components/Icon";
import getConfig from "./config";

const client = new ApolloClient({
  uri: `${getConfig('REACT_APP_REMOTE_URI')}graphql`,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <StyledNav>
          <StyledLogoWrapper>
            <Icon name="virti-feedback-logo" type="virtidesign" size="logo" />
          </StyledLogoWrapper>
        </StyledNav>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/forms/:formHash/:formUniqId" component={FormPage} />
          <Route path="/wizard/:formHash/:formUniqId" component={WizardForm} />
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
