import styled, { css } from "styled-components";

export const StyledTextWrapper = styled.div`
  margin-top: 0rem;
  margin-left: 0rem;
  display: grid;
`;
export const StyledHomepage = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  font-family: Ubuntu, sans-serif;
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

export const StyledMainHeader = styled.h1`
  color: var(--new-secondary-color);
  font-size: 2.5rem;
  font-weight: 500;
  width: 30rem;
  @media (min-width: 1500px) {
    font-size: 3.2rem;
    width: 35rem;
  }
  @media (min-width: 1700px) {
    font-size: 3.7rem;
    width: 40rem;
  }
`;

export const StyledSubHeading = styled.h2`
  font-weight: 500;
  font-size: 1.3rem;
  margin-bottom: 0rem;
  @media (max-width: 1500px) {
    font-size: 1.8rem;
  }
`;

export const StyledTextDescription = styled.h3`
  font-size: 0.9rem;
  font-weight: 500;
`;

export const StyledSignInLink = styled.a`
  color: var(--new-secondary-color);
  text-decoration: none;
`;
export const StyledSignUpButton = styled.a`
  text-align: center;
  color: var(--primary-button-text-color);
  padding: 0.6rem;
  display: inline-block;
  border-radius: var(--primary-button-border-radius);
  border: none;
  text-decoration: none;
  font-size: 1.2rem;
  width: 10rem;
  margin: 0rem 0rem 2.5rem 0rem;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: var(--new-primary-color);
  box-shadow: var(--card-box-shadow);
  font-family: Ubuntu, sans-serif;
  &:hover {
    opacity: 0.9;
    background-color: ${(props) =>
      props.bgColorHover ? props.bgColorHover : ""};
    color: var(--primary-button-text-color-hover);
    ${(props) =>
      props.type === "secondary" &&
      css`
        background-color: var(--secondary-button-bg-color-hover);
        color: var(--secondary-button-text-color-hover);
      `}
    ${(props) =>
      props.type === "tertiary" &&
      css`
        background-color: var(--tertiary-button-bg-color-hover);
        color: var(--tertiary-button-text-color-hover);
      `}
    ${(props) =>
      props.noBg &&
      css`
        text-decoration: underline;
      `}
  }
  &:active {
    ${(props) =>
      props.type === "primary" &&
      !props.noBg &&
      css`
        background-color: var(--primary-button-bg-color-active);
      `}
    ${(props) =>
      props.type === "secondary" &&
      !props.noBg &&
      css`
        background-color: var(--secondary-button-bg-color-active);
      `}
    ${(props) =>
      props.type === "tertiary" &&
      !props.noBg &&
      css`
        background-color: var(--tertiary-button-bg-color-active);
      `}
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--button-disabled-bg-color);
      color: var(--button-disabled-text-color);
      background-image: none;
      cursor: default;
      &:hover {
        background-image: none;
        background-color: var(--button-disabled-bg-color);
        color: var(--button-disabled-text-color);
      }
    `}
`;
export const StyledSlashWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const StyledSlash = styled.div`
  background: var(--new-tertiary-color);
  width: 60rem;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0rem;
  clip-path: polygon(38% 0, 100% 0%, 100% 100%, 21% 100%);

  z-index: -1;
`;

export const StyledIllustrationWrapper = styled.div`
  position: absolute;

  right: 4rem;
  @media (max-width: 1140px) {
    transform: scale(0.7);
  }

  @media (min-width: 1500px) {
    transform: scale(1.2);
  }

  @media (min-height: 825px) {
    top: 5rem;
  }
  @media (min-height: 1005px) {
    top: 10rem;
  }
  @media (min-height: 1205px) {
    top: 13rem;
  }
`;
