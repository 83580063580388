import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import {
  StyledPaginationButton,
  StyledPaginationButtons,
  StyledSubmitButton,
} from "../styled-components/StyledFinalForm";

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {},
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.page !== this.state.page) {
      window.scrollTo(0, 0);
    }
  };

  next = (values) => {
    this.setState((state) => ({
      page: Math.min(
        state.page + 1,
        React.Children.toArray(this.props.children)?.length - 1
      ),
      values,
    }));
  };

  previous = () => {
    this.setState((state) => ({
      page: Math.max(state.page - 1, 0),
    }));
  };

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];

    return activePage?.props?.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    } else {
      this.next(values);
    }
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    return (
      <Form
        initialValues={values}
        validate={(values) => {
          return this.validate(values);
        }}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {activePage}
              <StyledPaginationButtons className="buttons">
                {page > 0 && (
                  <StyledPaginationButton
                    previous={true}
                    type="button"
                    onClick={this.previous}
                  >
                    « Previous
                  </StyledPaginationButton>
                )}
                {!isLastPage && (
                  <StyledPaginationButton type="submit">
                    Next »
                  </StyledPaginationButton>
                )}
                {isLastPage && (
                  <StyledSubmitButton
                    type="submit"
                    disabled={submitting || this.props.loading}
                  >
                    Submit
                  </StyledSubmitButton>
                )}
              </StyledPaginationButtons>
            </form>
          );
        }}
      </Form>
    );
  }
}
