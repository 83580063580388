import styled from "styled-components";

export const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  text-align: left;
  padding: 0.5rem;
  margin: 0;
  font-family: var(--header-font);
  font-weight: 500;
  z-index: 3;
  @media (max-width: 1324px) {
    position: relative;
  }
`;

export const StyledLogoWrapper = styled.div`
  position: absolute;
  top: -6.5rem;
  left: -2.5rem;
  pointer-events: none;
`;
