// import * as Styled from "./HomePage.styled";

import Icon from "../../components/Icon";
import {
  StyledHomepage,
  StyledIllustrationWrapper,
  StyledMainHeader,
  StyledSignInLink,
  StyledSignUpButton,
  StyledSlash,
  StyledSlashWrapper,
  StyledSubHeading,
  StyledTextDescription,
  StyledTextWrapper,
} from "./HomePage.styled";
import getConfig from "../../config";

const HomePage = (props) => {
  const signInLink = getConfig('REACT_APP_SIGNIN_LINK');
  return (
    <StyledHomepage>
      <StyledTextWrapper>
        <StyledMainHeader>
          Sign up to Virti start using Virti Forms!
        </StyledMainHeader>
        <StyledSignUpButton href="https://www.virti.com/">
          Sign Up
        </StyledSignUpButton>
        <StyledSubHeading>Already a user?</StyledSubHeading>
        <StyledTextDescription>
          <StyledSignInLink href={signInLink + "feedback-forms"}>
            Sign in
          </StyledSignInLink>{" "}
          to access your forms.
        </StyledTextDescription>
      </StyledTextWrapper>
      <StyledSlashWrapper>
        <StyledIllustrationWrapper>
          {" "}
          <Icon name="ui-illustration" type="virtidesign" size="illustration" />
        </StyledIllustrationWrapper>

        <StyledSlash></StyledSlash>
      </StyledSlashWrapper>
    </StyledHomepage>
  );
};

HomePage.propTypes = {};

export default HomePage;
