import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const StyledBox = styled.div`
  padding: 2rem 0rem;
  display: grid;
  place-items: center;
  gap: 3rem;
`;

const StyledCheck = styled.div`
  animation-name: slide-animation;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  @keyframes slide-animation {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
  transition: 2s;
`;

function CompletionBox() {
  return (
    <StyledBox>
      <div>Thanks for completing this form! </div>{' '}
      <StyledCheck>
        <Icon name='checkbox' type='virtidesign' fill='#fff' size='medium' />
      </StyledCheck>
    </StyledBox>
  );
}

CompletionBox.propTypes = {};

export default CompletionBox;
