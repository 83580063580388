import gql from "graphql-tag";

export const READ_FORM = gql`
  query ($ID: ID!, $Hash: String!) {
    readOneFeedbackForm(filter: { ID: { eq: $ID }, Hash: { eq: $Hash } }) {
      ID
      Type
      Title
      Description
      Trigger
      Status
      Hash
      isCompletedByMember
      Pages {
        nodes {
          ID
          Title
          SortOrder
          Questions {
            nodes {
              ID
              Title
              Placeholder
              Type
              CharacterLimit
              SortOrder
              FirstLabel
              LastLabel
              Length
              Required
              Options {
                nodes {
                  ID
                  Name
                  Value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SUBMIT_FORM = gql`
  mutation submitForm($Input: CreateSubmissionInput!, $Answers: String) {
    submitForm(Input: $Input, Answers: $Answers) {
      ID
      Trigger
      Platform
      Answers {
        nodes {
          ID
          Answer
          OptionID
          LikertValue
          Options {
            nodes {
              ID
            }
          }
        }
      }
    }
  }
`;
