import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import SVG from "react-inlinesvg";

const getSize = (size) => {
  switch (size) {
    case "illustration":
      return "var(--icon-width-illustration)";
    case "logo":
      return "var(--icon-width-logo)";
    case "large":
      return "var(--icon-width-large)";
    case "medium":
      return "var(--icon-width-medium)";
    case "small":
      return "var(--icon-width-small)";
    case "tiny":
      return "var(--icon-width-tiny)";
    case "navbar":
      return "var(--icon-width-navbar)";
    default:
      return "auto";
  }
};

const StyledIcon = styled.img`
  ${(props) =>
    props.resizeWidth &&
    css`
      width: ${(props) => getSize(props.size)};
    `}
  ${(props) =>
    props.resizeHeight &&
    css`
      height: ${(props) => getSize(props.size)};
    `}
  ${(props) =>
    props.customWidth &&
    css`
      width: ${(props) => props.customWidth};
    `}
  ${(props) =>
    props.customHeight &&
    css`
      height: ${(props) => props.customHeight};
    `}
  ${(props) =>
    props.customOffsetY &&
    css`
      position: relative;
      top: ${(props) => props.customOffsetY};
    `}
  vertical-align: middle;
  ${(props) =>
    props.allWhite &&
    css`
      filter: brightness(10000%);
    `}
  ${(props) =>
    props.rotate &&
    css`
      transform: rotate(${props.rotate}deg);
    `}
`;

const StyledSVGWrapper = styled.span`
  svg {
    ${(props) =>
      props.resizeWidth &&
      css`
        width: ${(props) => getSize(props.size)};
      `}
    ${(props) =>
      props.resizeHeight &&
      css`
        height: ${(props) => getSize(props.size)};
      `}
    ${(props) =>
      props.customWidth &&
      css`
        width: ${(props) => props.customWidth};
      `}
    ${(props) =>
      props.customHeight &&
      css`
        height: ${(props) => props.customHeight};
      `}
    ${(props) =>
      props.customOffsetY &&
      css`
        position: relative;
        top: ${(props) => props.customOffsetY};
      `}
    ${(props) =>
      props.fill &&
      css`
        path[fill="#7a8ea2"] {
          fill: ${props.fill};
        }
      `}
  }
`;

const StyledSVG = styled(SVG)`
  vertical-align: middle;
  ${(props) =>
    props.rotate &&
    css`
      transform: rotate(${props.rotate}deg);
    `}
`;

const getSrc = (iconType, iconName) => {
  switch (iconType) {
    case "general":
      return "/images/icons/general/icon-" + iconName + ".png";
    case "virtidesign1":
      return "src/iconss/" + iconName + ".svg";
    case "virtidesign":
      return "/images/icons/virtidesign/" + iconName + ".svg";
    default:
      return "src/images/icons/virtidesign/checkbox.svg";
  }
};

const Icon = function (props) {
  const src = getSrc(props.type, props.name);
  if (props.type === "redesign" || props.type === "lineawesome") {
    return (
      <StyledSVGWrapper
        onClick={props.onClick}
        resizeHeight={props.resizeHeight}
        resizeWidth={props.resizeWidth}
        customHeight={props.customHeight}
        customWidth={props.customWidth}
        customOffsetY={props.customOffsetY}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        size={props.size}
        fill={props.fill}
      >
        <StyledSVG
          //   onClick={props.onClick}
          //   rotate={props.rotate}
          //   style={props.style}
          className={props.className}
          // allWhite={props.allWhite}
          src={src}
        />
      </StyledSVGWrapper>
    );
  }
  return (
    <StyledIcon
      onClick={props.onClick}
      customWidth={props.customWidth}
      customHeight={props.customHeight}
      customOffsetY={props.customOffsetY}
      rotate={props.rotate}
      style={props.style}
      className={props.className}
      allWhite={props.allWhite}
      resizeHeight={props.resizeHeight}
      resizeWidth={props.resizeWidth}
      size={props.size}
      src={src}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    />
  );
};

Icon.defaultProps = {
  allWhite: false,
  size: "small",
  resizeHeight: true,
  resizeWidth: true,
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  type: PropTypes.string,
  customOffsetY: PropTypes.string,
  allWhite: PropTypes.bool,
  resizeHeight: PropTypes.bool,
  resizeWidth: PropTypes.bool,
  style: PropTypes.object,
  rotate: PropTypes.number,
  fill: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default Icon;

export const StyledCircleWrapper = styled.div`
  border-radius: 30px;
  height: 35px;
  width: 35px;
  background-color: var(--nav-button-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
`;
