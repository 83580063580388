import { Field, useField } from "react-final-form";
import styled, { css } from "styled-components";
// import { Field, useField } from 'react-final-form';

export const StyledFormSection = styled.div`
  margin-bottom: 2rem;
  padding-top: 0.5rem;
  width: 40rem;
  max-width: 80%;
  margin: 2rem auto 0rem auto;
  text-align: left;
  font-family: var(--text-font);

  @media (max-width: 768px) {
    max-width: 90%;
  }
  ${
    "" /* animation-name: slide-animation;
  animation-duration: 3s;
  animation-iteration-count: 1;

  @keyframes slide-animation {
    0% {
      transform: translateX(80px);
    }
    10% {
      transform: translateX(0px);
    }
  } */
  }
`;

export const StyledQuestionWrapper = styled.div`
  margin: 1.5rem auto 4rem auto;
`;

export const StyledTextField = styled(Field)`
  border-radius: var(--secondary-button-border-radius);
  font-size: 0.9rem;
  font-family: var(--text-font);
  border: var(--input-border);
  height: var(--input-height);
  color: var(--text-input-text-color);
  border-radius: var(--input-border-radius);
  ::placeholder {
    color: var(--text-input-placeholder-text-color);
  }
  width: 100%;
  max-width: 100% !important;
  padding-left: 1rem;
  box-sizing: border-box;

  ${(props) =>
    props.disabled &&
    css`
      && {
        // overrides global style that's being applied somewhere :/ (possibly virtual human related)
        background-color: var(--disabled-form-input-bg-color);
      }
    `};
`;

export const StyledTextArea = styled(Field)`
  padding: var(--secondary-button-padding);
  border-radius: var(--secondary-button-border-radius);
  font-size: 0.9rem;
  font-family: var(--text-font);
  border: var(--input-border);
  color: var(--text-input-text-color);
  border-radius: var(--input-border-radius);
  ::placeholder {
    color: var(--text-input-placeholder-text-color);
  }
  width: 100%;
  max-width: 100%;
  resize: vertical;

  box-sizing: border-box;
`;

export const StyledTextFieldWrapper = styled.div`
  display: grid;
  width: 100%;
  place-items: center start;
`;

export const StyledDropdown = styled(Field)`
  font-size: 0.8rem;
  cursor: pointer;
  width: 100%;
  border: var(--input-border);

  color: var(--primary-font-color);
  padding: var(--secondary-button-padding);
  border-radius: var(--input-border-radius);
  background-color: var(--input-bg-color);
  font-size: 0.9rem;
  font-family: var(--text-font);
  border: var(--input-border);
  height: var(--input-height);
  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--disabled-form-input-bg-color);
    `}
`;

export const StyledRadioInputWrapper = styled.label`
  margin-bottom: 1rem;
`;

export const StyledRadioField = styled(Field)`
  position: relative !important;
  opacity: 1 !important;
  display: inline !important;
`;

export const StyledLikertWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  max-width: 85%;
  margin: auto;
  background-color: var(--primary-bg-color);
  padding: 1rem;
  border-radius: var(--card-border-radius);
`;

export const StyledOptionPoleText = styled.div`
  display: grid;
  place-items: center;
  text-align: center;
  font-size: 0.9rem;
  width: 8rem;
  max-width: 100%;
`;

export const StyledLikertFormOption = styled.label`
  flex: 1;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: .5rem;
  place-items: center;
  padding: 2rem 0rem;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 0.6rem;
  }
`;

export const StyledWrapper = styled.div`
  margin: 1rem 0rem;
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
`;

export const StyledFieldLabel = styled.label``;

const StyledError = styled.div`
  color: var(--validation-error-text-color);
  max-width: 100%;
  padding: var(--secondary-button-padding);
  border-radius: var(--input-border-radius);
  background-color: var(--input-bg-color);

  border: var(--input-border);
  border-color: var(--validation-error-input-border-color);

  margin: 0.5rem auto 2rem auto;
`;

export const Error = ({ name }) => {
  const {
    meta: { touched, error },
  } = useField(name, { subscription: { touched: true, error: true } });
  return touched && error ? <StyledError>{error}</StyledError> : null;
};

export const StyledQuestionTitle = styled.label`
  margin: 1rem 0rem 1rem 0rem;
  font-size: 1rem;
  font-weight: 600;
  word-wrap: break-word;
`;

export const StyledPageTitle = styled.h2`
  margin: 0rem 0rem 1.5rem 0rem;
  font-size: 1.2rem;
`;

export const StyledFormDescription = styled.div`
  background-color: var(--primary-bg-color);
  padding: 1rem 3rem;
  margin-bottom: 0rem;
  text-align: left;
  line-height: 1.4rem;
  border-radius: var(--card-border-radius);
  font-size: 0.8rem;
  color: var(--primary-font-color-light);
  word-wrap: break-word;
  white-space: pre-wrap;
`;

export const StyledSubmitButton = styled.button`
  color: var(--primary-button-text-color);
  padding: var(--primary-button-padding);
  display: inline-block;
  border-radius: var(--primary-button-border-radius);
  border: none;
  text-decoration: none;
  font-size: 1.2rem;
  width: 10rem;
  font-family: Ubuntu, sans-serif;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: var(--new-primary-color);

  &:hover {
    background-color: ${(props) =>
      props.bgColorHover
        ? props.bgColorHover
        : "var(--primary-button-bg-color-hover)"};
    color: var(--primary-button-text-color-hover);
    ${(props) =>
      props.type === "secondary" &&
      css`
        background-color: var(--secondary-button-bg-color-hover);
        color: var(--secondary-button-text-color-hover);
      `}
    ${(props) =>
      props.type === "tertiary" &&
      css`
        background-color: var(--tertiary-button-bg-color-hover);
        color: var(--tertiary-button-text-color-hover);
      `}
    ${(props) =>
      props.noBg &&
      css`
        text-decoration: underline;
      `}
  }
  &:active {
    ${(props) =>
      props.type === "primary" &&
      !props.noBg &&
      css`
        background-color: var(--primary-button-bg-color-active);
      `}
    ${(props) =>
      props.type === "secondary" &&
      !props.noBg &&
      css`
        background-color: var(--secondary-button-bg-color-active);
      `}
    ${(props) =>
      props.type === "tertiary" &&
      !props.noBg &&
      css`
        background-color: var(--tertiary-button-bg-color-active);
      `}
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--button-disabled-bg-color);
      color: var(--button-disabled-text-color);
      background-image: none;
      cursor: default;
      &:hover {
        background-image: none;
        background-color: var(--button-disabled-bg-color);
        color: var(--button-disabled-text-color);
      }
    `}
`;

export const StyledPaginationButton = styled.button`
  color: var(--primary-button-text-color);
  padding: var(--primary-button-padding);
  display: inline-block;
  border-radius: var(--primary-button-border-radius);
  border: none;
  text-decoration: none;
  font-size: 1.2rem;
  width: auto;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: var(--new-secondary-color);
  &:hover {
    background-color: ${(props) =>
      props.bgColorHover
        ? props.bgColorHover
        : "var(--primary-button-bg-color-hover)"};
    color: var(--primary-button-text-color-hover);
    ${(props) =>
      props.type === "secondary" &&
      css`
        background-color: var(--secondary-button-bg-color-hover);
        color: var(--secondary-button-text-color-hover);
      `}
    ${(props) =>
      props.type === "tertiary" &&
      css`
        background-color: var(--tertiary-button-bg-color-hover);
        color: var(--tertiary-button-text-color-hover);
      `}
    ${(props) =>
      props.noBg &&
      css`
        text-decoration: underline;
      `}
  }
  &:active {
    ${(props) =>
      props.type === "primary" &&
      !props.noBg &&
      css`
        background-color: var(--primary-button-bg-color-active);
      `}
    ${(props) =>
      props.type === "secondary" &&
      !props.noBg &&
      css`
        background-color: var(--secondary-button-bg-color-active);
      `}
    ${(props) =>
      props.type === "tertiary" &&
      !props.noBg &&
      css`
        background-color: var(--tertiary-button-bg-color-active);
      `}
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--button-disabled-bg-color);
      color: var(--button-disabled-text-color);
      background-image: none;
      cursor: default;
      &:hover {
        background-image: none;
        background-color: var(--button-disabled-bg-color);
        color: var(--button-disabled-text-color);
      }
    `}
  margin: auto;
  width: 8rem;
  ${(props) => props.previous && "margin-right: 1rem"};
`;

export const StyledPaginationButtons = styled.div`
  display: flex-center;
  place-items: center;
`;

export const StyledPageCount = styled.div`
  text-align: right;
  margin: 0rem auto 1rem auto;
  width: 80%;
  font-size: 0.8rem;
  color: var(--primary-font-color-light);
`;

export const StyledRadioWrapper = styled.div`
  display: grid;

  width: 80%;
  margin: auto;
  background-color: var(--primary-bg-color);
  padding: 1rem 2rem;
  border-radius: var(--card-border-radius);
  margin-bottom: 1rem;
`;

export const StyledRadioFormOption = styled.label`
  display: grid;
  grid-template-columns: auto 4fr;
  gap: 1rem;
  place-items: start;
  max-width: 20rem;
  padding: 1.5rem 0rem;
  text-align: left;
`;
