import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import { READ_FORM, SUBMIT_FORM } from "../../queries/FormQueries";
import * as Styled from "./FormPage.styled";
import styled from "styled-components";

import CompletionBox from "../../components/CompletionBox";
import WizardForm from "../../components/WizardForm";
import Spinner from "../../styled-components/StyledSpinner";
import { StyledFormDescription } from "../../styled-components/StyledFinalForm";
import { useEffect, useMemo, useState } from "react";
import { useSearchQuery } from "../../hooks/useSearchQuery";

const StyledFooter = styled.div`
  margin-top: 2rem;
  padding: 2rem 0rem 1rem 0rem;
  font-size: 0.8rem;
  color: var(--primary-font-color-light);
  border-top: var(--input-border);
`;

const StyledTitle = styled.h1`
  margin: 5rem 0rem 0rem 0rem;
  border-bottom: var(--primary-border);
  padding: 1.5rem 0rem;
  border-top-left-radius: var(--card-border-radius);
  border-top-right-radius: var(--card-border-radius);
  background-color: var(--new-secondary-color);
  color: var(--secondary-font-color);
`;

const StyledFormError = styled.div`
  margin: 4rem 0rem;
`;

const FormPage = (props) => {
  const [submitted, setSubmitted] = useState(false);

  let query = useSearchQuery();

  const trigger = query.get("trigger");
  const sourceId = query.get("sourceId");

  const { data, loading, error } = useQuery(READ_FORM, {
    variables: {
      ID: props.match.params.formUniqId,
      Hash: props.match.params.formHash,
    },
  });

  useEffect(() => {
    const disabled = data?.readOneFeedbackForm?.Status === "disabled";

    if ((!data && error) || disabled) {
      window.parent.postMessage(
        {
          event: "error",
          ID: props.match.params.formUniqId,
          Hash: props.match.params.formHash,
        },
        "*"
      );
    }
  }, [data, error, props]);

  const sendFormCompletedEvent = () => {
    if (window.parent === window) {
      return;
    }
    window.parent.postMessage(
      {
        event: "submit",
        ID: props.match.params.formUniqId,
        Hash: props.match.params.formHash,
      },
      "*"
    );
  };

  const sendFormErrorEvent = () => {
    if (window.parent === window) {
      return;
    }
    window.parent.postMessage(
      {
        event: "error",
        ID: props.match.params.formUniqId,
        Hash: props.match.params.formHash,
      },
      "*"
    );
  };

  const [submitForm, { loading: submissionLoading }] = useMutation(
    SUBMIT_FORM,
    {
      onError: () => {
        sendFormErrorEvent();
      },
    }
  );

  const source = useMemo(() => {
    switch (trigger) {
      case "BEGIN_SIM":
        return { SimulationID: sourceId };
      case "END_SIM":
        return { SimulationID: sourceId };
      case "BEGIN_VH":
        return { VirtualHumanID: sourceId };
      case "END_VH":
        return { VirtualHumanID: sourceId };
      case "BEGIN_COURSE":
        return { CourseID: sourceId };
      case "END_COURSE":
        return { CourseID: sourceId };
      default:
        return null;
    }
  }, [sourceId, trigger]);

  const onSubmit = (values) => {
    const getValue = (str) => {
      try {
        return JSON.parse(str);
      } catch (e) {
        return str;
      }
    };

    const answers = values.questions.reduce((acc, item) => {
      Object.keys(item).forEach((key) => {
        const id = key.slice(1);
        const value = getValue(item[key]);
        // non-text answers are stringified because they need to go into the Option.Value, and react-final-form-values are difficult to manipulate
        if (typeof value !== "string" && typeof value !== "number") {
          // is a JSON, add the value to Option.Value
          // right now it's guaranteed to be likert but the question type may be needed for parsing in the future
          // so I've given it a Value and Type, but only using the Value
          if (value.Type) {
            if (value.Type === "likertscalev2") {
              acc.push({
                QuestionID: id,
                LikertValue: value.Value,
              });
            }
            if (value.Type === "radiolist" || value.Type === "likertscale") {
              acc.push({
                QuestionID: id,
                OptionID: value.Value, // this value is an ID, it is very confusing, but it's been parsed to say value stupidly by me because we used to need Value. Now it's ID, and I need to change the parsing function
              });
            } else {
              // is a checkbox with one input

              const selectedOptions = [value.Value];

              acc.push({
                QuestionID: id,
                OptionIDs: selectedOptions,
              });
            }
          } else {
            // is a checkbox with multiple inputs
            const selectedOptions = value.map((q) => {
              const option = JSON.parse(q);

              return option.Value;
            });

            acc.push({
              QuestionID: id,
              OptionIDs: selectedOptions,
            });
          }

          // is text, add to Answer
        } else {
          acc.push({
            QuestionID: id,
            Answer: value,
          });
        }
      });
      return acc;
    }, []);

    const memberID = window.parent !== window ? query.get("userID") : null;
    const JSONAnswers = JSON.stringify(answers);

    submitForm({
      update: (proxy, mutationResult) => {
        if (mutationResult) {
          setSubmitted(true);
          sendFormCompletedEvent();
        }
      },

      variables: {
        Input: {
          FeedbackFormID: data?.readOneFeedbackForm?.ID,
          MemberID: memberID,
          Trigger: trigger,
          ...source,
          Platform: "web",
        },
        Answers: JSONAnswers,
      },
    });
  };

  return (
    <Styled.Page>
      <StyledTitle>{data?.readOneFeedbackForm?.Title} </StyledTitle>
      {data?.readOneFeedbackForm?.Status === "disabled" && (
        <StyledFormError>
          This form is not yet published, or responses have been disabled by the
          form administrator.
        </StyledFormError>
      )}
      {loading && <Spinner />}
      {data && !data.readOneFeedbackForm && (
        <StyledFormError>This form does not exist.</StyledFormError>
      )}

      {data?.readOneFeedbackForm?.Status !== "disabled" && (
        <div>
          <StyledFormDescription>
            {data?.readOneFeedbackForm?.Description}
          </StyledFormDescription>
          {submitted && <CompletionBox />}

          {submissionLoading && <Spinner />}
          {error && <div>Something went wrong.</div>}
          {!submitted && data && !submissionLoading && (
            <WizardForm
              loading={submissionLoading}
              onSubmit={onSubmit}
              data={data}
            />
          )}
        </div>
      )}

      <StyledFooter>Powered by Virti Feedback</StyledFooter>
    </Styled.Page>
  );
};

FormPage.propTypes = {
  match: PropTypes.object,
};

export default FormPage;
