import styled from "styled-components";

export const Page = styled.div`
  width: 50rem;
  max-width: 80%;
  margin: 0rem auto 4rem auto;
  padding-bottom: 0.5rem;
  text-align: center;
  font-family: var(--header-font);
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);

  @keyframes slide-animation {
    0% {
      transform: translateY(40px);
    }
    10% {
      transform: translateY(0px);
    }
  }
  transition: 2s;
  min-height: 25rem;
  @media (max-width: 768px) {
    max-width: 95%;
  }
`;
