import PropTypes from "prop-types";
import {
  StyledQuestionTitle,
  StyledTextFieldWrapper,
  StyledTextField,
  StyledLikertWrapper,
  StyledLikertFormOption,
  StyledRadioField,
  Error,
  StyledFormSection,
  StyledPageCount,
  StyledQuestionWrapper,
  StyledPageTitle,
  StyledRadioWrapper,
  StyledRadioFormOption,
  StyledTextArea,
  StyledOptionPoleText,
} from "../styled-components/StyledFinalForm.js";
import Wizard from "./Wizard";

const WizardForm = (props) => {
  return (
    <Wizard
      loading={props.loading}
      onSubmit={props.onSubmit}
      initialValues={{
        questions: props.data?.readOneFeedbackForm?.Pages.nodes.map((page) => {
          return {};
        }),
      }}
    >
      {props.data?.readOneFeedbackForm?.Pages?.nodes.map(
        (page, questionIndex) => {
          return (
            <Wizard.Page
              key={page.ID}
              validate={(values) => {
                const errors = {};
                page.Questions.nodes.forEach((question, index) => {
                  if (question.Required && question.Type) {
                    const ID = question.ID;
                    if (values.questions[questionIndex]) {
                      if (!values.questions[questionIndex][`q${ID}`]) {
                        errors[`q${ID}`] = "This question is required.";
                      } else if (
                        question.Type === "checkbox" &&
                        values.questions[questionIndex][`q${ID}`].length === 0
                      ) {
                        errors[`q${ID}`] = "Please select at least one option.";
                      }
                    }
                  }
                });

                return errors;
              }}
            >
              <StyledFormSection key={page.ID}>
                <StyledPageTitle>{page.Title}</StyledPageTitle>
                <div>
                  {page.Questions.nodes.map((question) => {
                    return (
                      <div key={question.ID}>
                        <StyledQuestionTitle htmlFor={`input${question.ID}`}>
                          {question.Title || "No Title"}{" "}
                          {question.Required ? "*" : ""}
                        </StyledQuestionTitle>
                        {question.Type === "text" && (
                          <StyledQuestionWrapper>
                            <StyledTextFieldWrapper>
                              <StyledTextField
                                id={`input${question.ID}`}
                                name={`questions[${questionIndex}].q${question.ID}`} // react final form won't accept an integer, even stringified, so it needs the "q"
                                component="input"
                                type="text"
                                placeholder={question.Placeholder}
                                maxLength={
                                  question.CharacterLimit > 0
                                    ? question.CharacterLimit
                                    : "1"
                                }
                              />
                            </StyledTextFieldWrapper>
                            {!props.loading && (
                              <Error name={`q${question.ID}`} />
                            )}
                          </StyledQuestionWrapper>
                        )}
                        {question.Type === "textarea" && (
                          <StyledQuestionWrapper>
                            <StyledTextFieldWrapper>
                              <StyledTextArea
                                id={`input${question.ID}`}
                                name={`questions[${questionIndex}].q${question.ID}`} // react final form won't accept an integer, even stringified, so it needs the "q"
                                component="textarea"
                                placeholder={question.Placeholder}
                                maxLength={
                                  question.CharacterLimit > 0
                                    ? question.CharacterLimit
                                    : "1"
                                }
                              />
                            </StyledTextFieldWrapper>
                            {!props.loading && (
                              <Error name={`q${question.ID}`} />
                            )}
                          </StyledQuestionWrapper>
                        )}
                        {question.Type === "likertscale" && (
                          <StyledQuestionWrapper>
                            <StyledLikertWrapper id={`input${question.ID}`}>
                              <StyledOptionPoleText>
                                {question.Options.nodes[0].Name}
                              </StyledOptionPoleText>
                              {question.Options.nodes.map((option, index) => {
                                return (
                                  <StyledLikertFormOption
                                    key={option.ID}
                                    htmlFor={`questions[${questionIndex}].q${question.ID}.o${option?.ID}`}
                                  >
                                    {index + 1}
                                    <StyledRadioField
                                      name={`questions[${questionIndex}].q${question.ID}`}
                                      id={`questions[${questionIndex}].q${question.ID}.o${option?.ID}`}
                                      component="input"
                                      type="radio"
                                      value={JSON.stringify({
                                        Value: option.ID,
                                        Type: question.Type,
                                      })}
                                    />
                                  </StyledLikertFormOption>
                                );
                              })}
                              <StyledOptionPoleText>
                                {
                                  question.Options.nodes[
                                    question.Options.length - 1
                                  ]?.Name
                                }
                              </StyledOptionPoleText>
                            </StyledLikertWrapper>

                            {!props.loading && (
                              <Error name={`q${question.ID}`} />
                            )}
                          </StyledQuestionWrapper>
                        )}
                        {question.Type === "likertscalev2" && (
                          <StyledQuestionWrapper>
                            <StyledLikertWrapper id={`input${question.ID}`}>
                              <StyledOptionPoleText>
                                {question.FirstLabel}
                              </StyledOptionPoleText>
                              {[...Array(question.Length)].map(
                                (emptyVal, index) => {
                                  return (
                                    <StyledLikertFormOption
                                      key={index + question.ID + "likert"}
                                      htmlFor={`questions[${questionIndex}].q${question.ID}.o${index}`}
                                    >
                                      {index + 1}
                                      <StyledRadioField
                                        name={`questions[${questionIndex}].q${question.ID}`}
                                        id={`questions[${questionIndex}].q${question.ID}.o${index}`}
                                        component="input"
                                        type="radio"
                                        value={JSON.stringify({
                                          Value: index + 1,
                                          Type: question.Type,
                                        })}
                                      />
                                    </StyledLikertFormOption>
                                  );
                                }
                              )}
                              <StyledOptionPoleText>
                                {question.LastLabel}
                              </StyledOptionPoleText>
                            </StyledLikertWrapper>
                            {!props.loading && (
                              <Error name={`q${question.ID}`} />
                            )}
                          </StyledQuestionWrapper>
                        )}
                        {question.Type === "radiolist" && (
                          <StyledQuestionWrapper>
                            <StyledRadioWrapper>
                              {question.Options.nodes.map((option, index) => {
                                return (
                                  <StyledRadioFormOption
                                    key={option.ID}
                                    htmlFor={`questions[${questionIndex}].q${question.ID}.o${option?.ID}`}
                                  >
                                    <StyledRadioField
                                      name={`questions[${questionIndex}].q${question.ID}`}
                                      id={`questions[${questionIndex}].q${question.ID}.o${option?.ID}`}
                                      component="input"
                                      type="radio"
                                      value={JSON.stringify({
                                        Value: option.ID,
                                        Type: question.Type,
                                      })}
                                    />

                                    {option.Name}
                                  </StyledRadioFormOption>
                                );
                              })}
                            </StyledRadioWrapper>
                            {!props.loading && (
                              <Error name={`q${question.ID}`} />
                            )}
                          </StyledQuestionWrapper>
                        )}
                        {question.Type === "checkbox" && (
                          <StyledQuestionWrapper>
                            <StyledRadioWrapper>
                              {question.Options.nodes.map((option, index) => {
                                return (
                                  <StyledRadioFormOption
                                    key={option.ID}
                                    htmlFor={`questions[${questionIndex}].q${question.ID}.o${option?.ID}`}
                                  >
                                    <StyledRadioField
                                      name={`questions[${questionIndex}].q${question.ID}`}
                                      id={`questions[${questionIndex}].q${question.ID}.o${option?.ID}`}
                                      component="input"
                                      type="checkbox"
                                      value={JSON.stringify({
                                        Value: option.ID,
                                        Type: question.Type,
                                      })}
                                    />

                                    {option.Name}
                                  </StyledRadioFormOption>
                                );
                              })}
                            </StyledRadioWrapper>
                            {!props.loading && (
                              <Error name={`q${question.ID}`} />
                            )}
                          </StyledQuestionWrapper>
                        )}
                      </div>
                    );
                  })}
                </div>
              </StyledFormSection>
              <StyledPageCount>
                Page {page.SortOrder + 1} of{" "}
                {props.data?.readOneFeedbackForm?.Pages.nodes.length}
              </StyledPageCount>
            </Wizard.Page>
          );
        }
      )}
    </Wizard>
  );
};

WizardForm.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default WizardForm;
